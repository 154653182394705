<template>
    <div class="modal fade" id="cookieModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="cookieModalLabel">Gestión de privacidad</h5>
                </div>
                <div class="modal-body">

                    <!-- Begin tab Consent -->
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="consent-tab" data-toggle="tab" href="#nav-consent" role="tab" aria-controls="consent" aria-selected="true">Consent</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="details" aria-selected="false">Details</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="about" aria-selected="false">About</a>
                        </li>
                    </ul>
                    <!-- End tab Consent -->

                    <!-- Begin tab Details -->
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-consent" role="tabpanel" aria-labelledby="nav-consent-tab">
                            <p class="fw-bold pt-2">Esta página web usa cookies</p>
                            <p class="fw-normal">Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico. Además, compartimos información sobre el uso que haga del sitio web con nuestros partners de redes sociales, publicidad y análisis web, quienes pueden combinarla con otra información que les haya proporcionado o que hayan recopilado a partir del uso que haya hecho de sus servicios. </p>
                        </div>

                        <div class="tab-pane fade" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                            <cookie-category 
                                v-for="category in categories" 
                                :key="category" 
                                :categoryName="category" 
                                :cookies="available_cookies[category]" 
                                :consent="consent"
                                @cookie:toggle="cookieToggle" 
                                @category:toggle="categoryToggle"
                            >
                            </cookie-category>
                        </div>

                        <!-- End tab Details -->
                        
                        <!-- Begin tab About -->
                        <div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                            <p class="pt-2">Las cookies son pequeños archivos de texto que las páginas web pueden utilizar para hacer más eficiente la experiencia del usuario.</p>
                            <p>La ley afirma que podemos almacenar cookies en su dispositivo si son estrictamente necesarias para el funcionamiento de esta página. Para todos los demás tipos de cookies necesitamos su permiso.</p>
                            <p>Esta página utiliza tipos diferentes de cookies. Algunas cookies son colocadas por servicios de terceros que aparecen en nuestras páginas.</p>
                            <p>En cualquier momento puede cambiar o retirar su consentimiento desde la Declaración de cookies en nuestro sitio web.</p>
                            <p>Obtenga más información sobre quiénes somos, cómo puede contactarnos y cómo procesamos los datos personales en nuestra Política de privacidad.</p>
                            <p>Al contactarnos respecto a su consentimiento, por favor, indique el ID y la fecha de su consentimiento.</p>
                        </div>
                        <!-- End tab About -->

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" data-dismiss="modal" id="submitDenyAll" class="btn btn-outline-primary modal_cancel_button mr-auto" @click="onDenyAll">Denegar todas</button>
                    <button type="button" data-dismiss="modal" id="submitSelection" class="btn btn-outline-primary modal_cancel_button mr-auto" @click="onSelection">Aceptar selección</button>
                    <button type="button" data-dismiss="modal" id="submitAcceptAll" class="btn btn-outline-primary modal_confirm_button" @click="onAcceptAll">Aceptar todas</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CookieCategory from './CookieCategory.vue';
    import axios from 'axios'
    import $ from 'jquery';
    
	export default {
        name: "gdpr-modal",
        props: ['available_cookies'],
        components: { 
            'cookie-category': CookieCategory 
        },

        mounted: function(){           
            this.loadCookieConsent();
            this.loadScripts();
        },

        watch: {
            available_cookies: function() {
                this.loadCookieConsent();
                this.loadScripts();
            }
        },

        data: function(){
            return {
                consent: {}
            }
        },

        computed: {
            categories ()   {
                return Object.keys(this.available_cookies)
            },
        },
        
        methods: {
            cookieToggle (event) {
                this.consent[event['name']] = event['value']
            },

            categoryToggle (event) {
                for (const cookie of this.available_cookies[event['name']]) {
                    this.consent[cookie['name']] = event['value'];
                }
            },

            onSelection () {
                this.sendConsent();
            },

            onAcceptAll() {
                for (const category in this.available_cookies) {
                    for (const cookie of this.available_cookies[category]) {
                        this.consent[cookie['name']] = true;
                    }
                }
                this.sendConsent();
            },

            onDenyAll() {
                for (const category in this.available_cookies) {
                    for (const cookie of this.available_cookies[category]) {
                        this.consent[cookie['name']] = category == 'necessary';
                    }
                }
                this.sendConsent();
            },

            sendConsent (){
                var base_url;
                if(window.location.hostname == 'localhost'){
                    base_url = 'https://localhost/aws';
                } else {
                    base_url = 'https://'+window.location.hostname;
                }

                axios.post('https://26qr9svo7l.execute-api.eu-west-1.amazonaws.com/v1/cookies',
                    {
                        url: window.location.href,
                        preferences: this.consent
                    }
                )
                .then(function (response) {
                    let cookie = response.data;

                    var postData = new FormData();
                    postData.append('cookie', cookie);

                    $('#cookieModal').css('opacity', 0);
                    $('#cookieModal').css('display', 'none');

                    $('#cookie_backdrop').remove();

                    axios.post(base_url+'/auth/modify_cookie_for_user', postData).then(function(response){
                        console.log('status', response.data.status);
                    }).catch(function(error){
                        console.log("error:", error);
                    });

                    this.loadScripts();
                }.bind(this))
                .catch(function (error) {
                    alert(error);
                });
            },
            
            loadCookieConsent(){
                var new_this = this;
                var postData = new FormData();

                var base_url;
                if(window.location.hostname == 'localhost'){
                    base_url = 'https://localhost/aws';
                } else {
                    base_url = 'https://'+window.location.hostname;
                }

                axios.post(base_url+'/auth/get_cookie_for_user', postData).then(function(response){
                    var cookieConsent = response.data.cookie;
                    var loaded_consent = JSON.parse(atob(cookieConsent.split('.')[1])).consent;

                    if(loaded_consent == null){
                        for (const category in new_this.available_cookies) {
                            for (const cookie of new_this.available_cookies[category]) {
                                new_this.consent[cookie['name']] = category == 'necessary';
                            }
                        }

                        if(!($('#cookie_backdrop').length)){
                            var newDiv = $('<div>', {
                                id: 'cookie_backdrop',
                                class: 'modal-backdrop fade show'
                            });
                            $('body').append(newDiv);
                        }

                        $('#cookieModal').css('opacity', 1);
                        $('#cookieModal').css('display', 'block');
                    } else {
                        new_this.consent = loaded_consent;
                    }
                }).catch(function(error){
                    console.log("error:", error);
                });
            },

            loadScripts() {
                let scripts = this.getScripts();
                let veredict = true;
                let cookies = null;
                if(this.consent != null){
                    for (const script of scripts){
                        veredict = true;
                        cookies = this.getCookiesFromScript(script)
                        for (const cookie of cookies){
                            veredict = veredict && this.consent[cookie]
                            if(!this.consent[cookie]){
                                document.cookie = cookie + "= ; Path=/; Domain=" + document.domain + "; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                            }
                        }
                        // if(veredict && veredict != undefined){
                        //     let src = $(`[cookie-consent-id=${script}]`).attr('src');
                        //     $(`[cookie-consent-id=${script}]`).remove();
                        //     $('<script>').attr('src', src).appendTo('head');
                        // }
                    }
                }
            },

            getScripts() {
                let scripts = [];
                for (const category in this.available_cookies) {
                    for (const cookie of this.available_cookies[category]) {
                        if(cookie['script'] != undefined && cookie['script'] != "" && !scripts.includes(cookie['script'])) {
                            scripts.push(cookie['script']);
                        }
                    }
                }
                return scripts;
            },

            getCookiesFromScript(script) {
                let cookies = [];
                for (const category in this.available_cookies) {
                    for (const cookie of this.available_cookies[category]) {
                        if(cookie['script'] == script){
                            cookies.push(cookie['name']);
                        }
                    }
                }
                return cookies;
            },

        },
  }
</script>
