<template>
	<div class="card mb-2">
        <div class="card-body">
            <h5 class="card-title">{{ cookie_data.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ cookie_data.provider }}</h6>
            <p class="card-text">
                {{ cookie_data.description }}
            </p>
            <p class="card-text">
                <strong>Dominio: </strong>{{ cookie_data.domain }}<br>
                <strong>Type: </strong>{{ cookie_data.type }}<br>
                <strong>Duration: </strong>{{ cookie_data.duration }}
            </p>
            <div class="form-check form-switch">
                <input v-if="category != 'necessary'" class="form-check-input cookie-switch" type="checkbox" :id="switchId" @click="onClickSwitch" v-model="isChecked"> <!-- checked dissabled -->
                <input v-else class="form-check-input cookie-switch" type="checkbox" :id="switchId" checked disabled>
                <label class="form-check-label" :for="switchId">Estado</label>
            </div>
        </div>
    </div>
    
</template>

<script>
	export default {
		name: 'cookie',
        props : ['cookie_data', 'category', 'consent'],
        data: function () {
            return {
                isChecked: this.consent[this.cookie_data['name']]
            }
        },

        watch: {
            consentStatus: function(newVal) { //oldVal suppressed to avoid error, check if error
                this.isChecked = newVal;
            }
        },

        computed: {
            switchId () {
                return this.cookie_data.name + 'Switch';
            },
            consentStatus () {
                return this.consent[this.cookie_data['name']];
            }
        },

        methods: {
            onClickSwitch(){
                this.$emit('cookie:toggle', {value: !this.isChecked, name: this.cookie_data.name});
            }
        }
  }
</script>
