<template>
  <div id="app">
    <gdpr-modal 
      :available_cookies="available_cookies"
    ></gdpr-modal>
  </div>
</template>

<script>
import GDPRModal from './components/GDPRModal.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    'gdpr-modal': GDPRModal,
  },
  mounted: function(){
    axios.get('https://26qr9svo7l.execute-api.eu-west-1.amazonaws.com/v1/cookies')
    .then(function (response) {
      this.available_cookies = response.data;
    }.bind(this))
    .catch(function (error) {
      alert(error);
    })
  },
  data: function(){
    return {
      available_cookies: {},
    }
  }
}
</script>

