<template>
    <div>
        <div>
            <div class="d-flex justify-content-between">
                <h5>
                    <a data-toggle="collapse" :href="collapseIdHref" role="button" aria-expanded="false" :aria-controls="collapseId">
                        {{ capitalize(categoryName) }}
                    </a>
                    <span class="badge bg-secondary text-white">{{ cookies.length }}</span>
                </h5>
                <div class="form-check form-switch">
                    <input v-if="categoryName != 'necessary'" class="form-check-input" :id="switchId" type="checkbox" @click="onClickSwitch" v-model="isChecked" :indeterminate.prop="isItermediate"> <!-- checked dissabled -->
                    <input v-else class="form-check-input" :id="switchId" type="checkbox" disabled checked>
                    <label class="form-check-label" :for="switchId">Estado</label>
                </div>
            </div>
            <p>
                Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.
            </p>
        </div>
        <div class="collapse" :id="collapseId">
            <div class="card card-body">
                <cookie 
                    v-for="cookie in cookies" 
                    @cookie:toggle="cookieToggle" 
                    :key="cookie.id" 
                    :cookie_data="cookie" 
                    :category="categoryName"
                    :consent="consent"
                >
                </cookie>
            </div>
        </div>
        <hr>
    </div>

</template>

<script>
    import Cookie from './Cookie.vue';
	export default {
        name: 'cookie-category',
        props: ['categoryName', 'cookies', 'consent'],

        components: {
            'cookie': Cookie,
        },

        computed: {
            collapseId ()   {
                return 'collapse' + this.capitalize(this.categoryName);
            },
            collapseIdHref() {
                return '#' + this.collapseId;
            },
            switchId () {
                return this.categoryName + 'Switch'
            },
        },

        methods: {
            capitalize (str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            cookieToggle (event) {
                this.$emit('cookie:toggle', event);
                if(this.isChecked && event['value'] == false){
                    this.isItermediate = true;
                }
            },
            onClickSwitch(){
                this.$emit('category:toggle', {value: !this.isChecked, name: this.categoryName})
            },
        },

        data: function () {
            return {
                isChecked: false,
                isItermediate: false,
            }
        },

        mounted: function(){
            this.isChecked = false;
        }
  }
</script>
